import { render, staticRenderFns } from "./UploadOrgPictures.vue?vue&type=template&id=f807082a&scoped=true"
import script from "./UploadOrgPictures.vue?vue&type=script&lang=js"
export * from "./UploadOrgPictures.vue?vue&type=script&lang=js"
import style0 from "./UploadOrgPictures.vue?vue&type=style&index=0&id=f807082a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f807082a",
  null
  
)

export default component.exports