<template>
  <div>
    <CCard>
      <CCardBody class="m-4">
        <CRow
          v-if="organisation.organisation_id && !isFetching"
          class="justify-content-between"
        >
          <CCol md="12" sm="12" lg="5">
            <CRow class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="d-flex justify-content-between">
                  <div class="col-lg-6 pl-0">
                    <label class="required my-2 font-weight-bolder">
                      Organisation
                    </label>
                  </div>
                  <div
                    v-if="
                      organisation.organisation_id &&
                      organisation.organisation_id.path_acronym
                    "
                    class="col-lg-6"
                  >
                    <CBadge
                      color="primary"
                      class="fs-14 my-2 float-right"
                      v-c-tooltip="'Path Acronym'"
                    >
                      {{ organisation.organisation_id.path_acronym }}
                    </CBadge>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <Select
                  name="organisation_id"
                  :value="organisation.organisation_id"
                  :options="
                    options && options['organisation']
                      ? options['organisation']
                      : []
                  "
                  :multiple="false"
                  :clearable="false"
                  @input="handleChangeSelect"
                />
              </div>
            </CRow>
            <!-- Image Slider starts -->
            <CRow
              v-if="
                document_type_id == CONST.JOB_PICTURE_DOC_TYPE &&
                !isImgUpdated &&
                getDocuments.length
              "
              class="justify-content-center row mt-5 mb-3"
            >
              <image-slider
                :class="`${
                  getDocuments.length > 1
                    ? 'double-image-slider'
                    : 'single-image-slider'
                }`"
                :dataSet="getDocuments"
                :cssClass="'job-picture-image w-100'"
                :backUpImage="backUpImage"
                :slidesToShow="
                  getDocuments.length > 1 ? 2 : getDocuments.length
                "
                :slidesToScroll="1"
                :defaultImageID="selectedPicture && selectedPicture.id"
                :actionItem="true"
                @selectedImage="selectedJobImage"
                @deleteItem="deleteDocument"
                @makeItemAsDefault="makeDefault"
              />
            </CRow>
            <!-- Image slider ends -->
            <!-- Upload section starts -->
            <CRow class="row mb-3">
              <div class="col-sm-12 col-lg-12 col-md-12">
                <div class="mt-4" v-if="isImgUpdated">
                  <div class="d-flex justify-content-center">
                    <button
                      type="button"
                      @click="resetForm()"
                      class="box-item text-center p-2 m-2 border rounded"
                    >
                      <div class="button-icon mb-2">
                        <i class="fa-solid fa-trash-can"></i>
                      </div>
                      <div class="col-sm-12">
                        <p class="m-0">Delete</p>
                      </div>
                    </button>
                    <button
                      type="button"
                      @click="useCrop()"
                      class="text-center p-2 m-2 border rounded"
                    >
                      <div class="button-icon mb-2">
                        <i class="fa-solid fa-crop"></i>
                      </div>
                      <div class="col-sm-12">
                        <p class="m-0">Crop</p>
                      </div>
                    </button>
                  </div>
                </div>
                <div
                  v-else-if="
                    getDocuments &&
                    selectedPicture &&
                    selectedPicture.id &&
                    !isImgUpdated &&
                    document_type_id == CONST.ORG_LOGO_DOC_TYPE
                  "
                  class="mt-3"
                  style="font-size: 12px"
                >
                  <div class="d-flex justify-content-start">
                    <div class="mr-2">
                      <p class="m-0">{{ selectedPicture.document_name }}</p>
                    </div>
                    <div>
                      <CButton
                        class="btn-ghost p-0"
                        @click="deleteDocument(selectedPicture)"
                        :disabled="selectedPicture.default_document"
                      >
                        <em class="fas fa-trash-can cursor-pointer" />
                      </CButton>
                    </div>
                  </div>
                </div>
                <div class="mt-4">
                  <div class="d-flex justify-content-between">
                    <div>
                      <input
                        id="upload-images"
                        name="upload-images"
                        type="file"
                        ref="fileref"
                        :accept="fileType"
                        @change="handleFile"
                        :disabled="!organisation.organisation_id"
                      />
                    </div>
                    <div>
                      <label
                        class="font-weight-bolder"
                        v-if="document_type_id == CONST.JOB_PICTURE_DOC_TYPE"
                      >
                        <span>
                          <em
                            class="
                              fa-solid fa-circle-info
                              text-primary
                              cursor-pointer
                            "
                            v-c-tooltip="
                              'Best Resolution - 72DPI (850x250 pixels)'
                            "
                          ></em>
                        </span>
                      </label>
                    </div>
                  </div>
                  <div class="mt-3">
                    <div class="m-0" style="font-size: 10px">
                      <strong class="text-black">Note :</strong>
                      <ul>
                        <li>
                          {{
                            document_type_id == CONST.JOB_PICTURE_DOC_TYPE
                              ? "Upload multiple images for selection during job advert creation "
                              : "Upload the logo for the facility"
                          }}
                        </li>
                        <li>Use crop for Larger Images</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </CRow>
            <CRow class="row mb-3">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="d-flex justify-content-end">
                  <CButton
                    type="button"
                    color="primary"
                    variant="outline"
                    class="btn btn-outline-primary m-2"
                    @click="resetForm()"
                  >
                    Cancel
                  </CButton>
                  <CButton
                    type="button"
                    color="primary"
                    class="btn btn-primary m-2"
                    @click="submit()"
                  >
                    Upload
                  </CButton>
                </div>
              </div>
            </CRow>
            <!-- Upload section ends -->
          </CCol>
          <CCol md="12" sm="12" lg="6" class="border rounded preview-box">
            <CRow class="row justify-content-center m-0 mb-3 border">
              <!-- Picture Preview start -->
              <div class="header w-100 px-3 pt-3">
                <img
                  id="header_content"
                  :src="header_content"
                  @error="$event.target.src = header_content"
                  class="w-100"
                  alt="header"
                />
              </div>
              <div v-if="document_type_id == CONST.ORG_LOGO_DOC_TYPE">
                <div class="body">
                  <img
                    id="preview_img"
                    :src="getImageUrl"
                    @error="$event.target.src = backUpImage"
                    :style="{
                      left: windowWidth < 500 ? '12%' : '8%',
                      top: windowWidth < 500 ? '11%' : '7%',
                    }"
                    style="
                      position: absolute;
                      width: 13%;
                      height: auto;
                      max-width: 80px;
                      z-index: 99;
                    "
                    alt="body-content"
                    class="rounded-circle"
                  />
                </div>
                <div class="image-content w-100">
                  <img
                    id="preview_img"
                    :src="image_placeholder"
                    @error="$event.target.src = image_placeholder"
                    class="w-100"
                    alt="header"
                  />
                </div>
              </div>
              <div v-if="document_type_id == CONST.JOB_PICTURE_DOC_TYPE">
                <div class="image-content w-100">
                  <img
                    id="preview_img"
                    :src="getImageUrl"
                    @error="$event.target.src = backUpImage"
                    class="w-100"
                    alt="header"
                  />
                </div>
              </div>
              <div class="footer w-100 px-3 pt-3">
                <img
                  id="footer_content"
                  :src="content_placeholder"
                  @error="$event.target.src = content_placeholder"
                  class="w-100"
                  alt="footer"
                />
              </div>
              <!-- Picture Preview ends -->
            </CRow>
          </CCol>
        </CRow>
        <!-- Picture section starts here -->
      </CCardBody>
    </CCard>
    <div>
      <CropperModal
        v-if="cropperModal.isShowPopup"
        :isShowPopup="cropperModal.isShowPopup"
        :fileData="cropperModal.fileData"
        modalColor="primary"
        :buttonLabel="cropperModal.buttonLabel"
        @modalCallBack="cropperModalCallBack"
        :aspectRatio="cropperModal.aspectRatio"
      />
    </div>
    <div>
      <CToaster v-if="errorToaster.isError" position="bottom-right">
        <CToast :show="true" class="bg-danger text-white">
          {{ errorToaster.message }}
        </CToast>
      </CToaster>
    </div>
    <modal
      :modalTitle="'Notification'"
      :modalColor="'primary'"
      :size="'sm'"
      :modalContent="'Are you sure to delete this picture?'"
      :isShowPopup="deleteModal.popup"
      :buttons="['Cancel', 'Confirm']"
      :modalCallBack="deleteModalCallBack"
      :closeOnBackdrop="false"
    />
    <modal
      :modalTitle="'Notification'"
      :modalColor="'primary'"
      :modalContent="'Please click upload or cancel to confirm your image selection!'"
      :isShowPopup="confirmModal.popup"
      :buttons="['Ok']"
      :modalCallBack="confirmModalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>

<script>
import Vue from "vue";
import Modal from "../../components/reusable/Modal.vue";
import { mapActions, mapGetters } from "vuex";
import { CONSTANT as CONST, appendBaseUrlWithLink } from "../../helpers/helper";

import CropperModal from "@/components/reusable/CropperModal";
import Select from "@/components/reusable/Fields/Select.vue";
import ImageSlider from "../../components/reusable/ImageSlider.vue";

import header_content from "../../../public/img/job-content.svg";
import image_placeholder from "../../../public/img/image_placeholder.png";
import content_placeholder from "../../../public/img/job-line.svg";

export default {
  name: "UploadOrgPictures",
  props: {
    document_type_id: {
      type: Number,
      default: 0,
    },
    isImgUpdated: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CropperModal,
    Modal,
    Select,
    ImageSlider,
  },
  data() {
    return {
      header_content,
      image_placeholder,
      content_placeholder,
      CONST,
      payload: {},
      fileType: "image/png, image/jpeg, image/jpg, image/gif",
      cropperModal: {
        isShowPopup: false,
        fileData: null,
        buttonLabel: "Apply",
        aspectRatio: 1,
      },
      errorToaster: {
        isError: false,
        message: null,
      },
      deleteModal: {
        popup: false,
        item: {},
      },
      confirmModal: {
        popup: false,
      },
      tempFile: {},
      organisation: {},
      selectedPicture: {},
      isFetching: false,
      appendBaseUrlWithLink,
    };
  },
  watch: {
    getDocuments: function (value) {
      if (value.length == 1 && value[0]?.default_document == false) {
        this.makeDefault(value[0]);
      }
    },
  },
  computed: {
    ...mapGetters([
      "getOrgID",
      "getAllOrganisationDocumentByType",
      "allCustomerOrganisation",
      "getWindowWidth",
    ]),
    getDocuments() {
      let docs = this.getAllOrganisationDocumentByType(
        this.document_type_id
      ).map((val) => ({
        id: val.organisation_document_id,
        url: appendBaseUrlWithLink(val?.link_to_doc),
        ...val,
      }));
      if (docs.length) this.selectedJobImage(docs[0]);
      else this.selectedJobImage({});
      return docs;
    },
    backUpImage() {
      if (this.document_type_id == CONST.ORG_LOGO_DOC_TYPE)
        return "/img/hospital.png";
      if (this.document_type_id == CONST.JOB_PICTURE_DOC_TYPE)
        return "/img/job-image.jpg";
    },
    getImageUrl() {
      if (this.payload?.file) return URL.createObjectURL(this.payload.file);
      if (this.selectedPicture?.url) return this.selectedPicture.url;
      return this.backUpImage;
    },
    options() {
      return {
        organisation: this.allOrganisationListOpt,
      };
    },
    allOrganisationListOpt() {
      return (
        this.allCustomerOrganisation.map((v) => ({
          code: v?.organisation_id,
          label: v?.organisation?.name,
          customer_id: v?.customer_uid,
          path_acronym: v?.path_acronym,
        })) || []
      );
    },
    windowWidth() {
      return this.getWindowWidth;
    },
  },
  methods: {
    ...mapActions([
      "fetchAllVersionDocumentByOrgId",
      "createDocumentForOrganisation",
      "deleteDocumentForOrganisation",
      "showToast",
      "fetchAllCustomerOrganisation",
      "makeOrgDocumentDefault",
    ]),
    showToaster({ message }) {
      if (this.$refs.fileref) this.$refs.fileref.value = "";
      this.errorToaster.isError = true;
      this.errorToaster.message = message;
      setTimeout(() => {
        this.errorToaster.isError = false;
        this.errorToaster.message = null;
      }, 5000);
    },
    handleChangeSelect(name, value) {
      Vue.set(this.organisation, name, value);
      this.resetForm();
      const { code: organisation_id } = value;
      this.isFetching = true;
      this.fetchAllVersionDocumentByOrgId({ organisation_id }).then(() => {
        this.isFetching = false;
      });
    },
    getExtension(filename) {
      let parts = filename.split(".");
      return _.lowerCase(parts[parts.length - 1]);
    },
    handleFile(event) {
      const size = event.target.files[0].size;
      if (Math.round(size / (1024 * 1024)) <= 2) {
        const file = event.target.files[0];
        const fileExt = this.getExtension(file.name);
        const fileTypeString = this.fileType;
        if (fileTypeString.includes(fileExt)) {
          if (file.type.startsWith("image/")) {
            this.payload = {
              ...this.payload,
              file,
              fileName: file.name ? file.name : "untitled.png",
            };
            this.tempFile = file;
            this.$emit("imgUpdated", true);
            this.confirmUpload();
          }
        } else {
          const message = "Only png/jpeg/jpg/gif allowed";
          this.showToaster({ message });
        }
      } else {
        const message = "File too Big, please select a file less than 2mb";
        this.showToaster({ message });
      }
    },
    useCrop() {
      this.cropperModal.fileData = this.tempFile;
      if (this.document_type_id == 17) this.cropperModal.aspectRatio = 1;
      if (this.document_type_id == 19) this.cropperModal.aspectRatio = 2;
      this.cropperModal.isShowPopup = true;
    },
    cropperModalCallBack(action, data) {
      if (action === false) {
        this.cropperModal.isShowPopup = false;
        this.cropperModal.fileData = null;
        this.cropperModal.aspectRatio = 0;
      } else {
        let { fileBlob, fileName } = data;
        this.payload = { ...this.payload, fileName };
        this.payload.file = fileBlob;
        this.cropperModal.isShowPopup = false;
        this.cropperModal.fileData = null;
        this.cropperModal.aspectRatio = 0;
        this.$emit("imgUpdated", true);
        this.confirmUpload();
      }
    },
    submit() {
      if (!this.payload.file) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please Choose Image File!",
        });
        return;
      }
      let finalPayload = {
        ...this.payload,
        organisation_id: this.organisation.organisation_id?.code,
        document_type_id: this.document_type_id,
      };
      this.createDocumentForOrganisation(finalPayload).then(() => {
        this.resetForm();
      });
    },
    deleteDocument(item) {
      this.deleteModal.popup = true;
      this.deleteModal.item = item;
    },
    confirmUpload() {
      this.confirmModal.popup = true;
    },
    deleteModalCallBack(action) {
      if (action == "Confirm") {
        this.deleteDocumentForOrganisation({
          organisation_id: this.deleteModal?.item.organisation_id,
          organisation_document_id: this.deleteModal?.item.id,
        }).then(() => {
          this.deleteModal.popup = false;
          this.selectedPicture = {};
        });
      } else {
        this.deleteModal.popup = false;
      }
    },
    confirmModalCallBack(action) {
      if (action == "Ok") {
        this.confirmModal.popup = false;
      } else {
        this.confirmModal.popup = false;
      }
    },
    makeDefault(item) {
      this.makeOrgDocumentDefault({
        organisation_id: item.organisation_id,
        organisation_document_id: item.id,
      });
    },
    resetForm() {
      this.$emit("imgUpdated", false);
      this.payload = {};
      this.tempFile = {};
      if (this.$refs.fileref) this.$refs.fileref.value = "";
      if (this.getDocuments.length) this.selectedJobImage(this.getDocuments[0]);
      else this.selectedJobImage({});
    },
    selectedJobImage(item) {
      this.selectedPicture = item;
    },
  },
  mounted() {
    this.$store.commit("SET_ALL_DOCUMENT_BY_ORGANISATION_ID", []);
    this.$store.commit("LAST_FETCHED_DOCUMENT_BY_ORGANISATION_ID", "");
    this.fetchAllCustomerOrganisation().then((res) => {
      if (this.allOrganisationListOpt.length) {
        const first_org = this.allOrganisationListOpt[0];
        this.handleChangeSelect("organisation_id", first_org);
      }
    });
  },
  created() {
    window.addEventListener("resize", () => {
      this.$store.commit("SET_WINDOW_WIDTH");
    });
  },
};
</script>

<style lang="scss" scoped>
.preview-box {
  padding: 22px 24px 20px;
  background-color: var(--modal-background);
  border-radius: 3px;
  box-shadow: 0 17px 50px 0 rgba(var(--shadow-rgb), 0.19),
    0 12px 15px 0 rgba(var(--shadow-rgb), 0.24);
}

.upload_file #upload-image {
  display: none;
}
.upload_file #upload-image + label {
  display: inline-block;
  text-align: center;
  margin-bottom: 0;
  background: #dd3651;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
  width: 110px;
}
.upload_file #upload-image + label:hover {
  background: #ee4763;
}
.upload_file #upload-image + label:after {
  color: #ee4763;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}
.parent_div {
  position: relative;
}
.child_div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.single-image-slider {
  width: 50%;
}
@media screen and (max-width: 768px) {
  .single-image-slider {
    width: 15%;
  }
}
.double-image-slider {
  width: 80%;
}
@media screen and (max-width: 768px) {
  .double-image-slider {
    width: 60%;
  }
}
</style>
